<template>
  <div class="timeline-wrapper" ref="timeline" :class="{ jumping: jumping }">
    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->
    
    <div v-waypoint="{ active: true, callback: onWaypoint }"></div>

    <div class="timeline">
      <div
        class="date-wrapper"
        v-for="(dateTimeline, date) in timeline"
        :key="date"
      >
        <div class="date-display">{{ getDateDisplay(date) }}</div>

        <div class="timeline-entry" v-for="(entry, i) in dateTimeline" :key="i">
          <markdown-chat-entry
            v-if="
              determineEntryType(entry) == 'Soteria.Models.MarkdownChatEntry'
            "
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
            :membership="membership"
            :my-presence-id="myPresenceId"
          ></markdown-chat-entry>

          <tasks-chat-entry
            v-if="determineEntryType(entry) == 'Soteria.Models.TasksChatEntry'"
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
            :membership="membership"
            :my-presence-id="myPresenceId"
            @view-tasks="viewTasks"
          ></tasks-chat-entry>

          <media-chat-entry
            v-if="determineEntryType(entry) == 'Soteria.Models.MediaChatEntry'"
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
            :membership="membership"
            :my-presence-id="myPresenceId"
            :tenant-id="tenantId"
          ></media-chat-entry>

          <status-chat-entry
            v-if="determineEntryType(entry) == 'Soteria.Models.StatusChatEntry'"
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
          >
          </status-chat-entry>

          <guidance-chat-entry
            v-if="determineEntryType(entry) == 'Soteria.Models.GuidanceChatEntry'"
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :my-presence-id="myPresenceId"
            :membership="membership"
            :entry="entry"
          >
          </guidance-chat-entry>

          <audio-entry-details
            v-if="
              determineEntryType(entry) == 'Soteria.Models.AudioEntryDetails'
            "
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
            :membership="membership"
            :my-presence-id="myPresenceId"
            :tenant-id="tenantId"
          ></audio-entry-details>

          <help-video-chat-entry
            v-if="
              determineEntryType(entry) == 'Soteria.Models.HelpVideoChatEntry'
            "
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
            :membership="membership"
            :my-presence-id="myPresenceId"
            :tenant-id="tenantId"
            :owner-type="ownerType"
          ></help-video-chat-entry>

          <announcement-chat-entry
            v-if="
              determineEntryType(entry) == 'Soteria.Models.AnnouncementChatEntry'
            "
            :id="getEntryId(entry)"
            :ref="getEntryId(entry)"
            :entry="entry"
            :membership="membership"
            :my-presence-id="myPresenceId"
            :tenant-id="tenantId"
          >
          </announcement-chat-entry>

          <!-- <div v-if="isUnknownEntryType(entry)"> -->
            <!-- {{ entry }} -->
          <!-- </div> -->
        </div>

        <!-- <div>
          OwnerID: {{ ownerId }}
        </div> -->
      </div>
    </div>

    <!-- Typing indicators -->
    <!-- <div class="typing-indicators" v-if="typingPresenceIdsWithoutMe.length">
      {{ getPresenceNames(typingPresenceIdsWithoutMe) }}
      {{ typingPresenceIdsWithoutMe.length == 1 ? "is" : "are" }} typing...
    </div> -->
    <!-- / Typing indicators -->
  </div>
</template>

<script>
import MarkdownChatEntry from "./Entries/MarkdownChatEntry.vue";
import MediaChatEntry from "./Entries/MediaChatEntry.vue";
import moment from "moment";
import StatusChatEntry from "./Entries/StatusChatEntry.vue";
import { mapActions, mapGetters } from "vuex";
import AudioEntryDetails from "./Entries/AudioEntryDetails.vue";
import HelpVideoChatEntry from "./Entries/HelpVideoChatEntry.vue";
import GuidanceChatEntry from "./Entries/GuidanceChatEntry.vue";
import TasksChatEntry from "./Entries/TasksChatEntry.vue";
import UpdatesLastReadPointer from "../../mixins/UpdatesLastReadPointer";
const _ = require("lodash");
import eventBus from "../../event-bus";
import AnnouncementChatEntry from './Entries/AnnouncementChatEntry.vue';

const KNOWN_TYPES = [
  "Soteria.Models.MarkdownChatEntry",
  "Soteria.Models.MediaChatEntry",
  "Soteria.Models.StatusChatEntry",
  "Soteria.Models.HelpVideoChatEntry",
  "Soteria.Models.TasksChatEntry",
];

export default {
  components: {
    MarkdownChatEntry,
    MediaChatEntry,
    StatusChatEntry,
    AudioEntryDetails,
    HelpVideoChatEntry,
    TasksChatEntry,
    GuidanceChatEntry,
    AnnouncementChatEntry
  },

  props: [
    "timeline",
    "myPresenceId",
    "ownerType",
    "ownerId",
    "membership",
    "tenantId",
  ],

  mixins: [UpdatesLastReadPointer],

  watch: {
    timeline() {
      if (this.justTriggeredWaypoint) {
        let vm = this;
        window.setTimeout(function () {
          vm.justTriggeredWaypoint = false;
        }, 300);
      } else {
        this.scrollTimelineToBottom();
      }
    },
  },

  mounted() {
    let vm = this;

    vm.scrollTimelineToBottom();
    this.listenToScrollTimelineToEntryWithIdEvent();

    // this.$signalR.on("NewChatEntry", (newChatEntry) => {
    //   vm.handleNewChatEntry(newChatEntry, vm);
    // });

    // listen to event bus
    eventBus.$on("new-chat-entry-for-timeline", (newChatEntry) => {
      vm.handleNewChatEntry(newChatEntry, vm);
    });

    // this.$signalR.on("ControlMessage", (controlMessage) => {
    //   vm.handleControlMessage(controlMessage, vm);
    // });
    eventBus.$on("control-message-for-timeline", (controlMessage) => {
      vm.handleControlMessage(controlMessage, vm);
    });
  },

  beforeDestroy() {
    eventBus.$off("new-chat-entry-for-timeline");
    eventBus.$off("control-message-for-timeline");
    // this.$signalR.off("NewChatEntry");
    // this.$signalR.off("ControlMessage");
  },

  data() {
    return {
      justTriggeredWaypoint: false,
      scrollingToEntryWithId: null,
      jumping: false
    };
  },

  computed: {
    ...mapGetters("timeline", {
      isLoading: "isLoading",
      // typingPresenceIds: "typingPresenceIds",
      loadedEarliest: "loadedEarliest",
      waypointEnabled: "waypointEnabled",
    }),

    ...mapGetters("incidentViewer", {
      incidentMembership: "membership",
    }),

    ...mapGetters("chatViewer", {
      chatMembership: "membership",
    }),

    typingPresenceIdsWithoutMe() {
      return _.without(_.uniq(this.typingPresenceIds), this.myPresenceId);
    },
  },

  methods: {
    ...mapActions("timeline", {
      addTimelineEntry: "addTimelineEntry",
      handlePresenceTyping: "handlePresenceTyping",
      loadEarlierMessages: "loadEarlierMessages",
      isLoading: "isLoading",
      removeTypingPresenceId: "removeTypingPresenceId",
    }),

    ...mapActions("chats", {
      updateChatGroupMember: "updateChatGroupMember",
    }),

    ...mapActions("incidents", {
      updateIncidentGroupMember: "updateIncidentGroupMember",
    }),

    ...mapActions("metadata", {
      addGuidToMetadata: "addGuidToMetadata",
    }),

    getEntryId(entry) {
      return "timeline-entry-" + entry.id;
    },

    handleNewChatEntry(newChatEntry, vm) {
      // let vm = this;
      let { entry } = newChatEntry;
      if (this.isSignalREventForThisTimeline(entry, vm)) {
        vm.removeTypingPresenceId(entry.createdByPresenceId);
        vm.addTimelineEntry(entry);
        if (entry.metadataId) {
          // alert('metadata id');
          // alert(entry.metadataId);
          console.log("Adding metadata id:");
          console.log(entry.metadataId);
          this.addGuidToMetadata(entry.metadataId);
        }
        if (this.ownerType == 2) {
          // This is a chat - update my group member
          this.updateChatGroupMember({
            presenceId: vm.myPresenceId,
            groupId: vm.ownerId,
            groupMemberDetails: {
              lastReadPointer: window.moment(),
            },
          });
          vm.updateServerLastReadPointer(
            vm.tenantId,
            vm.myPresenceId,
            vm.ownerId
          );
        }
        if (this.ownerType == 3) {
          // This is an incident - update my group member
          this.updateIncidentGroupMember({
            presenceId: vm.myPresenceId,
            groupId: vm.ownerId,
            groupMemberDetails: {
              lastReadPointer: window.moment(),
            },
          });
          vm.updateServerLastReadPointer(
            vm.tenantId,
            vm.myPresenceId,
            vm.ownerId
          );
        }
        // vm.scrollTimelineToBottom();
      }
    },

    handleControlMessage(controlMessage, vm=null) {
      if (!vm) {
        vm = this;
      }
      // let vm = this;
      if (controlMessage.messageType == 5) {
        // GroupTyping
        let messageBody = JSON.parse(controlMessage.messageBody);
        let groupId = messageBody.groupId;
        let presenceId = messageBody.presenceId;

        if (groupId === this.ownerId) {
          console.log('Group id from signal R: ' + groupId);
          console.log('Owner id of itmeline: ' + this.ownerId);

          this.handlePresenceTyping(presenceId);

          // console.log('Group typing message received');
          // console.log('Group ID from message: ')
          // console.log(groupId);
          // console.log('Group ID from timeline: ')
          // console.log(this.ownerId);

          // this.$nextTick(() => {
          // vm.scrollTimelineToBottom();
          // });
        }
      }
    },

    onWaypoint(params) {
      console.log("WAYPOINT");
      console.log(params);
      if (!this.waypointEnabled) {
        return false;
      }
      let shouldConsiderBasedOnDirection =
        // params.direction == 'top'
        params.going == "in";
      if (!shouldConsiderBasedOnDirection) {
        return false;
      }
      if (
        !this.isLoading &&
        !this.loadedEarliest &&
        !this.justTriggeredWaypoint
      ) {
        console.log("loading earlier");

        this.justTriggeredWaypoint = true;

        this.loadEarlierMessages();
      }
    },

    isSignalREventForThisTimeline(entry, vm = null) {
      if (!vm) {
        vm = this;
      }

      let { ownerId, ownerType } = entry;

      console.log('Entry owner ID');
      console.log(ownerId);
      console.log('Timeline owner ID');
      console.log(vm.ownerId);

      console.log('Entry owner type');
      console.log(ownerType);
      console.log('Timeline owner type');
      console.log(vm.ownerType);

      return vm.ownerId === ownerId && vm.ownerType === ownerType;
    },

    determineEntryType(entry) {
      return entry["content"]["$t"].replace(", Soteria.Models", "");
    },

    getDateDisplay(date) {
      let todayMoment = moment().startOf("day");
      let yesterdayMoment = moment().subtract(1, "day").startOf("day");
      let m = moment(date);
      if (m.isSameOrAfter(todayMoment)) {
        return "Today";
      } else if (m.isSameOrAfter(yesterdayMoment)) {
        return "Yesterday";
      } else {
        return m.format("dddd Do MMMM Y");
      }
    },

    isUnknownEntryType(entry) {
      let entryType = this.determineEntryType(entry);
      return !KNOWN_TYPES.includes(entryType);
    },

    scrollTimelineToBottom() {
      let vm = this;
      // vm.$refs.timeline.scrollTop = vm.$refs.timeline.scrollHeight;
      // console.log(vm.$refs.timeline.scrollHeight);
      vm.$nextTick(() => {
        let elem = vm.$refs["timeline"];
        if (elem && elem.scrollHeight) {
          elem.scrollTop = elem.scrollHeight;
        }
      });
    },

    viewTasks() {
      this.$emit("view-tasks");
    },

    getPresenceNames(presenceIds) {
      let names = [];

      _.each(presenceIds, (presenceId) => {
        let member = null;
        if (this.chatMembership && this.chatMembership.length) {
          member = _.find(this.chatMembership, { id: presenceId });
        }
        if (
          !member &&
          this.incidentMembership &&
          this.incidentMembership.length
        ) {
          member = _.find(this.incidentMembership, { id: presenceId });
        }
        names.push(member ? member.displayName : "Unknown");
      });

      return names.join(" and ");
    },

    listenToScrollTimelineToEntryWithIdEvent() {
      let vm = this;
      eventBus.$on("scroll-timeline-to-entry-with-id", (entryId) => {
        if (vm.scrollingToEntryWithId !== entryId) {
          vm.scrollingToEntryWithId = entryId;
          window.setTimeout(() => {
            vm.scrollTimelineToEntryWithId(entryId);
          }, 100);
        }
      });
    },

    scrollTimelineToEntryWithId(entryId) {
      let vm = this;

      vm.jumping = true;

      vm.$nextTick(() => {
        let timeline = vm.$refs["timeline"];
        let entry = vm.$refs["timeline-entry-" + entryId];

        console.log("Let's try scroll timeline");
        console.log(timeline);
        console.log(entry);

        if (timeline && entry) {
          let entryOffsetTop = entry[0].$el.offsetTop;
          timeline.scrollTop = entryOffsetTop - entry[0].$el.scrollHeight * 2;
        }

        vm.$nextTick(() => {
          vm.jumping = false;
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.timeline-wrapper {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &.jumping {
    scroll-behavior: auto !important;
  }

  // .timeline {
  //   height: 100%;

  // }
}

.date-wrapper {
  text-align: center;
}

.date-display {
  text-align: center;
  margin-top: 10px;
  background-color: #f5c2cb;
  padding: 5px 14px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.timeline-entry {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.loader {
  text-align: center;
  margin-bottom: 20px;
}

.typing-indicators {
  padding-top: 5px;
  padding-bottom: 15px;
}
</style>