<template>
  <a-modal
    centered
    :width="modalWidth"
    v-model="showModal"
    title="Send Photo"
    :footer="null"
  >
    <loading-screen :is-loading="isUploading"></loading-screen>
    <div class="camera-button">
      <a-button
        v-if="!isCameraOpen && !isPhotoTaken"
        size="large"
        block
        :type="isCameraOpen ? 'danger' : 'primary'"
        @click="toggleCamera"
      >
        <span v-if="!isCameraOpen">Start Webcam</span>
        <span v-else>Stop Webcam</span>
      </a-button>
    </div>

    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <a-spin></a-spin>
    </div>

    <div
      v-if="isCameraOpen || isPhotoTaken"
      v-show="!isLoading"
      class="camera-box"
      style="text-align: center"
      :class="{ flash: isShotPhoto }"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video
        v-show="!isPhotoTaken"
        ref="camera"
        :width="mediaWidth"
        :height="mediaHeight"
        autoplay
      ></video>

      <canvas
        v-show="isPhotoTaken"
        id="photoTaken"
        ref="canvas"
        :width="mediaWidth"
        :height="mediaHeight"
      ></canvas>
    </div>

    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
      <a-button
        style="margin-top: 15px"
        block
        type="primary"
        size="large"
        @click.prevent="takePhoto"
      >
        Take Photo
      </a-button>
    </div>

    <a-row :gutter="20" v-if="isPhotoTaken && !isLoading">
      <a-col :span="12">
        <div class="camera-shoot">
          <a-button
            style="margin-top: 15px"
            block
            type="default"
            size="large"
            @click.prevent="retakePhoto"
          >
            Retake Photo
          </a-button>
        </div>
      </a-col>
      <a-col :span="12" v-if="isPhotoTaken && !isLoading">
        <div class="camera-download">
          <!-- <a id="downloadPhoto" download="my-photo.jpg"></a> -->
          <a-button
            type="primary"
            block
            style="margin-top: 15px"
            size="large"
            @click.prevent="uploadImage"
          >
            Upload Photo
          </a-button>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import timelines from "../../api/timelines";
import LoadingScreen from "../LoadingScreen.vue";

export default {
  components: { LoadingScreen },
  props: ["visible", "myPresenceId"],

  data() {
    return {
      windowWidth: window.innerWidth,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,

      isUploading: false,

      cameraStreamStopped: false,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  computed: {
    ...mapGetters("timeline", {
      tenantId: "tenantId",
      ownerType: "ownerType",
      ownerId: "ownerId",
    }),

    modalWidth() {
      const MAX_WIDTH = 1200;
      if (this.windowWidth < MAX_WIDTH) {
        return this.windowWidth - 100;
      }
      return MAX_WIDTH;
    },

    mediaMaxWidth() {
      return this.modalWidth - 300;
    },

    aspectRatio() {
      return 337.5 / 450;
    },

    mediaWidth() {
      return this.mediaMaxWidth;
    },

    mediaHeight() {
      return this.mediaMaxWidth * this.aspectRatio;
    },

    thumbnailWidth() {
      return 200;
    },

    thumbnailHeight() {
      return this.thumbnailWidth * this.aspectRatio;
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
          this.stopCamera();
        }
      },
    },
  },

  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    startCamera() {
      this.cameraStreamStopped = false;
      this.isCameraOpen = true;
      this.createCameraElement();
    },

    stopCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },

    stopCameraAfterTakingPhoto() {
      this.isCameraOpen = false;
      this.stopCameraStream();
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          console.log("cannot load camera");
          // this.$message.error("Cannot load camera");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    retakePhoto() {
      this.stopCamera();
    },

    takePhoto() {
      if (!this.isCameraOpen) {
        this.startCamera();
      }
      if (this.cameraStreamStopped) {
        this.startCamera();
      }
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        this.mediaWidth,
        this.mediaHeight
      );

      this.cameraStreamStopped = true;
      this.stopCameraAfterTakingPhoto();
    },

    async srcToFile(src, fileName, mimeType) {
      return fetch(src)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], fileName, { type: mimeType });
        });
    },

    async generateThumbDataUri(datas) {
      let wantedWidth = this.thumbnailWidth;
      let wantedHeight = this.thumbnailHeight;

      return new Promise((resolve) => {
        // We create an image to receive the Data URI
        var img = document.createElement("img");

        // When the event "onload" is triggered we can resize the image.
        img.onload = function () {
          // We create a canvas and get its context.
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          // We set the dimensions at the wanted size.
          canvas.width = wantedWidth;
          canvas.height = wantedHeight;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

          var dataURI = canvas.toDataURL("image/jpeg");
          resolve(dataURI);
        };

        img.src = datas;
      });
    },

    async uploadImage() {
      const dataUrl = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg");
      const file = await this.srcToFile(dataUrl);

      let vm = this;

      // Scale down thumbnail
      let thumbBase64 = await this.generateThumbDataUri(dataUrl);

      let content = {
        $t: "Soteria.Models.MediaChatEntry, Soteria.Models",
        $v: {
          MediaContentType: "image/jpeg",
          MediaType: 1,
          ThumbnailBase64Url: thumbBase64,
        },
      };

      let params = {
        OwnerType: vm.ownerType,
        OwnerId: vm.ownerId,
        Behaviour: 0,
        CreatedByPresenceId: vm.myPresenceId,
        Content: content,
      };

      vm.isUploading = true;
      timelines
        .postTimelineEntry(this.tenantId, params)
        .then((r) => {
          let timelineEntryId = r.data.id;
          timelines
            .addTimelineEntryMedia(timelineEntryId, file)
            .then(() => {
              vm.isUploading = false;
              vm.stopCamera();
              vm.$emit("image-uploaded");
            })
            .catch((e) => {
              console.log(e);
              vm.isUploading = false;
              vm.$message.error("Error uploading image");
            });
        })
        .catch((e) => {
          vm.isUploading = false;
          console.log(e);
          vm.$message.error("Error uploading image");
        });
    },
  },
};
</script>

<style>
</style>  