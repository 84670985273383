<template>
    <div class="announcement-chat-entry-inner" :class="{ 'sent-by-me': sentByMe }">
        <!-- Announcement Cards -->
        <div class="announcement-response-cards" v-if="
            (myGroupMember && myGroupMember.isResponder) || amIAnAdmin
        ">
            <announcement-response-card v-for="(responseGroup, i) in responses" :key="getAnnouncementGroupKey(i)"
                :is-inline="true" :response-group="responseGroup" :tenant-id="tenantId"
                :org-id="announcement.ownerId"
                :announcement-id="announcement.id"></announcement-response-card>
        </div>
        <!-- / Announcement Cards -->

        <!-- My Response -->
        <div class="my-response" v-if="myGroupMember">
            <div class="have-i-responded">
                {{
                    haveIResponded
                        ? "You responded " + myChoice["name"]
                        : "You have not responded yet"
                }}
            </div>

            <div class="response-buttons">
                <announcement-choice-button v-for="(choice, i) in choices" :key="getAnnouncementChoiceButtonKey(i)"
                    :choice="choice" :is-selected="isChoiceSelected(choice)"
                    :announcement-guidance-pack-id="announcementGuidancePackId"
                    :announcement-content-id="announcementContentId"
                    :tenant-id="tenantId"
                    :announcement="announcement"
                    :my-presence-id="myPresenceId"
                    >
                </announcement-choice-button>
            </div>
        </div>
        <!-- / My Response -->
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import AnnouncementResponseCard from '../../../../views/Announcements/Show/AnnouncementResponseCard.vue';
import AnnouncementChoiceButton from '../../../../views/Announcements/Show/AnnouncementChoiceButton.vue';

export default {
    props: ['announcement', 'tenantId', 'sentByMe', 'timeline'],

    components: { AnnouncementResponseCard, AnnouncementChoiceButton },

    methods: {
        getAnnouncementGroupKey(i) {
            return "announcement-group-" + i;
        },

        getAnnouncementChoiceButtonKey(i) {
            return "announcement-choice-button-" + i;
        },

        isChoiceSelected(choice) {
            if (this.myChoice) {
                return choice.id === this.myChoice.id;
            }
            return false;
        }
    },

    computed: {
        ...mapGetters('missionControl', {
            presences: 'presences',
            organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor"
        }),

        amIAnAdmin() {
            let org = _.find(this.organisationsIAmAnAdminFor, {
                id: this.announcement.ownerId,
            });
            return !!org;
        },

        announcementId() {
            return this.announcement.id;
        },

        groupMembers() {
            return this.announcement.groupMembers;
        },

        myPresenceId() {
            let myPresenceIds = this.presences.map(presence => presence.id);
            let presenceIdsInAnnouncement = this.groupMembers.map(member => member.presenceId);
            let intersection = _.intersection(myPresenceIds, presenceIdsInAnnouncement);

            return intersection.length > 0 ? intersection[0] : null;
        },

        myGroupMember() {
            return this.myPresenceId ? this.groupMembers.find(member => member.presenceId === this.myPresenceId) : null;
        },

        haveIResponded() {
            return this.myGroupMember ? this.myGroupMember['content'] : false;
        },

        myChoice() {
            if (
                this.haveIResponded
                && this.announcement['content']['$v']['choices']
            ) {
                return _.find(
                    this.announcement['content']['$v']['choices'],
                    {
                        id: this.myGroupMember['content']['$v']['response']
                    }
                );
            }
            return null;
        },

        choices() {
            if (this.announcement) {
                return this.announcement['content']['$v']['choices'];
            }
            return [];
        },

        responses() {
            let responses = [];

            if (!this.announcement || !this.groupMembers) {
                return [];
            }

            responses.push({
                id: null,
                name: 'People have not responded',
                members: _.filter(this.groupMembers, groupMember => {
                    return !groupMember.content;
                })
            });

            _.each(this.announcement['content']['$v']['choices'], choice => {
                let res = {
                    ...choice
                };
                res.members = _.filter(this.groupMembers, groupMember => {
                    return (
                        groupMember['content']
                        && groupMember['content']['$v']['response']
                        && groupMember['content']['$v']['response'] == choice.id
                    )
                });
                responses.push(res);
            });

            return responses;
        },

        announcementGuidancePackId() {
            if (this.timeline && this.timeline.length) {
                let firstElement = this.timeline[0];
                if (
                    firstElement["content"]["$t"] ==
                    "Soteria.Models.GuidanceChatEntry, Soteria.Models"
                ) {
                    return firstElement["content"]["$v"]["guidancePackId"];
                }
            }
            return null;
        },

        announcementContentId() {
            if (this.timeline && this.timeline.length) {
                let firstElement = this.timeline[0];
                if (
                    firstElement["content"]["$t"] ==
                    "Soteria.Models.GuidanceChatEntry, Soteria.Models"
                ) {
                    return firstElement["content"]["$v"]["contentId"];
                }
            }
            return null;
        },
    }
}
</script>

<style lang="scss">
.announcement-chat-entry-inner {
    .announcement-response-card {
        margin-bottom: 15px;
        background: rgba(255, 255, 255, 0.1);

        &:not(.sent-by-me) {
            border-color: rgba(0, 0, 0, 0.2);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.sent-by-me {
        .presence-badge {
            border-color: #fff;
            color: #fff;
        }
    }

    .my-response {
        margin-top: 15px;
        margin-bottom: 5px;

        .have-i-responded {
            font-size: 14px;
            // font-weight: 500;
            margin-bottom: 15px;
        }
    }
}
</style>