<template>
  <a-modal
    v-model="visible"
    :title="title ? title : `We've sent an alert. Help is coming. Tap or type your location.`"
    :footer="null"
  >
    <!-- Is loading locations -->
    <div class="is-loading-locations" v-if="isLoadingLocations">
      <a-spin></a-spin>
    </div>
    <!-- / Is loading locations -->

    <!-- Locations -->
    <div class="locations-list" v-if="!isLoadingLocations">
      <!-- Location doesn't matter -->
      <div class="location-list-item" @click.prevent="closeModal">
        <div class="left">My location doesn't matter</div>
        <div class="right"></div>
      </div>
      <!-- / Location doesn't matter -->

      <div
        class="location-list-item"
        v-for="(location, i) in locations"
        @click.prevent="() => handleLocationSelected(location)"
        :key="i"
      >
        <div class="left">
          {{ location["displayName"] }}
        </div>
        <div class="right">
          {{ location["roomCode"] }}
        </div>
      </div>
    </div>
    <!-- / Locations -->

    <!-- Custom location -->
    <div class="custom-location">
      <div class="left">
        <a-input
          v-model="customLocation"
          @keyup.enter="sendCustomLocation"
          size="large"
          placeholder="Or enter a custom location..."
          class="message-input"
        ></a-input>
      </div>
      <div class="right">
        <a-button
          :loading="isSending"
          :disabled="!canSendCustomLocation"
          size="large"
          type="primary"
          @click.prevent="sendCustomLocation"
          >Send</a-button
        >
      </div>
    </div>
    <!-- / Custom location -->
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import timelines from "../../../api/timelines";
import organisations from "../../../api/organisations";
export default {
  props: ["myPresenceId", "title"],
  data() {
    return {
      visible: true,

      customLocation: "",
      isSending: false,

      isLoadingLocations: false,
      locations: [],
    };
  },
  created() {
    this.loadLocations();
  },
  computed: {
    ...mapGetters("timeline", {
      tenantId: "tenantId",
      ownerType: "ownerType",
      ownerId: "ownerId",
      organisationId: "organisationId",
    }),

    canSendCustomLocation() {
      return this.customLocation.trim().length > 0;
    },
  },
  methods: {
    closeModal() {
      this.visible = false;
    },

    sendCustomLocation() {
      if (!this.canSendCustomLocation) {
        return false;
      }

      this.sendLocation(this.customLocation);
    },

    sendLocation(locationName) {
      let vm = this;

      let content = {
        $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
        $v: {
          Text: "My location: " + locationName,
        },
      };

      let params = {
        OwnerType: vm.ownerType,
        OwnerId: vm.ownerId,
        Behaviour: 1,
        CreatedByPresenceId: vm.myPresenceId,
        Content: content,
      };

      vm.isSending = true;
      timelines
        .postTimelineEntry(this.tenantId, params)
        .then(() => {
          vm.customLocation = "";
          vm.isSending = false;
          vm.visible = false;
        })
        .catch((e) => {
          vm.isSending = false;
          console.log(e);
          vm.$message.error("Error sending message");
        });
    },

    loadLocations() {
      let vm = this;
      vm.isLoadingLocations = true;
      organisations
        .getLocationsForOrg(this.tenantId, this.organisationId)
        .then((r) => {
          vm.isLoadingLocations = false;
          vm.locations = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingLocations = false;
          // vm.$message.error("Error loading locations");
        });
    },

    handleLocationSelected(location) {
        this.sendLocation(location.displayName);
    },
  },
};
</script>

<style scoped lang="scss">
.is-loading-locations {
  text-align: center;
  margin-bottom: 10px;
}

.locations-list {
  .location-list-item {
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #eee;
    margin-bottom: 10px;

    &:hover {
      //   color: #5155ea;
      //   border: 1px solid #5155ea;
      background: #f9f9f9;
    }

    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
      padding-left: 15px;
    }
  }
}

.custom-location {
  padding-top: 20px;
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
  }
}
</style>