<template>
  <div class="send-message-wrapper">
    <div class="left">
      <a-input
        @keyup.enter="sendMessage"
        @keydown="updateLastKeypress"
        v-model="messageToSend"
        size="large"
        placeholder="Message"
        class="message-input"
      />
    </div>
    <div class="right">
      <a-button
        @click.prevent="sendMessage"
        size="large"
        type="primary"
        :loading="isSending"
        :disabled="!canSendMessage"
        >Send</a-button
      >
      <a-button
        @click.prevent="showQuickMessagesModal"
        size="large"
        icon="thunderbolt"
        class="quick-messages-button"
      ></a-button>
      <a-modal
        v-model="quickMessagesModalVisible"
        title="Quick Message"
        :footer="false"
      >
        <div class="buttons-wrapper">
          <div
            class="quick-message-option"
            v-for="(option, i) in quickMessages"
            :key="i"
          >
            <a-button
              @click.prevent="sendQuickMessage(option)"
              block
              size="large"
            >
              {{ option.displayName }}
            </a-button>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import timelines from "../../api/timelines";
export default {
  props: ["myPresenceId"],
  data() {
    return {
      quickMessagesModalVisible: false,
      isSending: false,

      messageToSend: "",

      isTypingNotifier: null,

      lastKeypress: null,
    };
  },

  computed: {
    ...mapGetters("timeline", {
      tenantId: "tenantId",
      quickMessages: "quickMessages",
      ownerType: "ownerType",
      ownerId: "ownerId",
    }),

    canSendMessage() {
      return this.messageToSend.trim().length > 0;
    },
  },

  created() {
    let vm = this;
    this.isTypingNotifier = window.setInterval(function () {
      if (vm.messageToSend.trim().length > 0) {
        if (
          vm.lastKeypress &&
          window
            .moment(vm.lastKeypress)
            .isAfter(window.moment().subtract(10, "seconds"))
        ) {
          // console.log('Notify that typing');
          vm.notifyThatTyping();
        } else {
          // console.log('time elapsed');
        }
      }
    }, 2000);
  },

  beforeDestroy() {
    window.clearInterval(this.isTypingNotifier);
    this.isTypingNotifier = null;
  },

  methods: {
    updateLastKeypress() {
      this.lastKeypress = window.moment();
    },

    showQuickMessagesModal() {
      this.quickMessagesModalVisible = true;
    },

    hideQuickMessageModal() {
      this.quickMessagesModalVisible = false;
    },

    sendQuickMessage(quickMessage) {
      this.hideQuickMessageModal();
      this.messageToSend = quickMessage.displayName;
      this.sendMessage();
    },

    async notifyThatTyping() {
      // console.log('Tenant ID');
      // console.log(this.tenantId);
      // console.log('Owner ID');
      // console.log(this.ownerId);
      // console.log('My Presence ID');
      // console.log(this.myPresenceId);
      try {
        await this.$signalR.invoke("NotifyGroupTyping", {
          TenantId: this.tenantId,
          GroupId: this.ownerId,
          PresenceId: this.myPresenceId,
        });
      } catch (err) {
        console.log(err);
      }
    },

    sendMessage() {
      let vm = this;

      let content = {
        $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
        $v: {
          Text: vm.messageToSend,
        },
      };

      // let content = {
      // Message: vm.messageToSend
      // }

      let params = {
        OwnerType: vm.ownerType,
        OwnerId: vm.ownerId,
        Behaviour: 0,
        CreatedByPresenceId: vm.myPresenceId,
        Content: content,
      };

      vm.isSending = true;
      timelines
        .postTimelineEntry(this.tenantId, params)
        .then(() => {
          vm.messageToSend = "";
          vm.isSending = false;
        })
        .catch((e) => {
          vm.isSending = false;
          console.log(e);
          vm.$message.error("Error sending message");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.send-message-wrapper {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 8px rgb(0 0 0 / 4%);

  .left {
    flex-grow: 1;

    .message-input {
      border: none;
    }
  }

  .right {
    padding-left: 10px;
    flex-shrink: 1;

    .quick-messages-button {
      margin-left: 5px;
    }
  }
}

.buttons-wrapper {
  .quick-message-option {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>