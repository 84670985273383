<template>
  <div>
    <div
      class="flex-wrapper"
      :class="{ 'sent-by-me': sentByMe, 'has-location': hasLocation, 'links-to-external-url': linksToExternalUrl }"
    >
      <div class="entry-wrapper">
        <!-- <div>{{ entry['ownerId'] }}</div> -->
        <div class="entry" @click.prevent="handleMessageClicked">
          <div class="sent-by" v-if="!sentByMe">
            {{ getMemberName(entry["createdByPresenceId"]) }}
          </div>

          <div class="message-wrapper">
            <a-icon v-if="linksToExternalUrl" style="margin-right: 8px" type="link" />{{ message }}
          </div>

          <!-- Show more / Show less -->
          <div class="show-more-less-wrapper" v-if="canShowMoreOrLess">
            <a-button
              @click.prevent="toggleShowMore"
              icon="down"
              type="link"
              size="small"
              v-if="!showMore"
              >Show more</a-button
            >
            <a-button
              @click.prevent="toggleShowMore"
              icon="up"
              type="link"
              size="small"
              v-if="showMore"
              >Show less</a-button
            >
          </div>
          <!-- / Show more / show less -->

          <div v-if="hasLocation" class="location">
            <a-icon type="environment" /> Location
          </div>
        </div>
        <div class="sent-at" :key="timeAgoRefreshKey">
          {{ displayTimeAgo(entry["whenCreated"]) }}
        </div>
      </div>
    </div>

    <!-- Location modal -->
    <a-modal
      class="fullscreen-modal"
      v-model="locationModalVisible"
      centered
      title="Location"
      :footer="false"
    >
      <GmapMap
        :center="locationPosition"
        :zoom="14"
        style="width: 100%; height: 100%"
      >
        <GmapMarker :position="locationPosition" />
      </GmapMap>
    </a-modal>
    <!-- / Location modal -->
  </div>
</template>

<script>
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
const _ = require("lodash");
export default {
  props: ["entry", "myPresenceId", "membership"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      locationModalVisible: false,
      showMore: false,
    };
  },
  computed: {
    messageProperty() {
      if (
        this.entry
        && this.entry['content']['$v']['linkTitle']
        && this.entry['content']['$v']['text']
      ) {
        return 'linkTitle';
      }
      return 'text';
    },

    linksToExternalUrl() {
      return this.entry && this.entry['content']['$v']['linkTitle'] && this.entry['content']['$v']['text'];
    },

    message() {
      if (this.canShowMoreOrLess) {
        if (this.showMore) {
          return this.entry["content"]["$v"][this.messageProperty];
        }
        return this.entry["content"]["$v"][this.messageProperty].substring(0, 180) + "...";
      }
      return this.entry["content"]["$v"][this.messageProperty];
    },

    sentByMe() {
      // return this.entry.;
      return this.entry["createdByPresenceId"] === this.myPresenceId;
    },

    hasLocation() {
      return this.entry["content"]["$v"]["geoPoint"];
    },

    locationPosition() {
      if (this.hasLocation) {
        return {
          lat: this.entry["content"]["$v"]["geoPoint"]["latY"],
          lng: this.entry["content"]["$v"]["geoPoint"]["longX"],
        };
      }
      return {};
    },

    canShowMoreOrLess() {
      return this.entry["content"]["$v"][this.messageProperty].length > 180;
    },
  },
  methods: {
    getMemberName(presenceId) {
      let member = _.find(this.membership, { id: presenceId });
      return member ? member.displayName : "Unknown";
    },

    handleMessageClicked() {
      if (this.hasLocation) {
        this.locationModalVisible = true;
      }
      if (this.linksToExternalUrl) {
        window.open(this.entry['content']['$v']['text']);
      }
      return false;
    },

    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;

  .entry-wrapper {
    flex-grow: 1;
    max-width: 45%;
    // background: #5155ea;
  }

  .entry {
    background: #e0e0e0;
    border-radius: 7px;
    padding: 13px 15px;
    border-bottom-left-radius: 0 !important;
    white-space: pre-line;
  }

  .sent-at {
    text-align: left;
    margin-top: 5px;
    font-size: 12px;
  }

  &.has-location, &.links-to-external-url {
    cursor: pointer;
  }

  &.links-to-external-url {
    .message-wrapper {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }

  &.sent-by-me {
    justify-content: flex-end;

    .entry {
      background: #5155ea;
      color: #fff;
      border-bottom-left-radius: 7px !important;
      border-bottom-right-radius: 0 !important;
    }

    .sent-at {
      text-align: right;
    }
  }

  .sent-by {
    color: #5155ea;
    // margin-bottom: 1px;
  }

  .location {
    color: #377e20;
    margin-top: 3px;
  }
  &.sent-by-me {
    .location {
      color: #b9eea8 !important;
    }
  }

  .show-more-less-wrapper {
    margin-top: 15px;
    .ant-btn-link {
      color: #fff;
    }
  }
}
</style>