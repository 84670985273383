<template>
  <div>
    <div class="flex-wrapper" :class="{ 'sent-by-me': sentByMe }">
      <div class="entry-wrapper">
        <div class="entry" @click.prevent="handleMessageClicked">
          <div class="sent-by" v-if="!sentByMe">
            {{ getMemberName(entry["createdByPresenceId"]) }}
          </div>

          <div
            class="loader"
            v-if="isLoadingThumbnail && thumbnailLoadAgainTryCount == 0"
          >
            <a-spin></a-spin>
          </div>

          <div
            v-if="thumbnailUrl"
            :key="thumbnailKeyUniq"
            class="image"
            :style="{ 'background-image': 'url(' + this.thumbnailUrl + ')' }"
          ></div>
        </div>
        <div class="sent-at" :key="timeAgoRefreshKey">
          {{ displayTimeAgo(entry["whenCreated"]) }}
        </div>
      </div>
    </div>

    <!-- Modal -->
    <a-modal
      class="fullscreen-modal"
      v-model="modalVisible"
      centered
      title="Media"
      :footer="false"
    >
      <div class="loader" v-if="isLoading && loadAgainTryCount == 0">
        <a-spin></a-spin>
      </div>
      <div :key="mediaKeyUniq"
        class="fullscreen-image"
        :style="{ 'background-image': 'url(' + this.mediaUrl + ')' }"
      ></div>
    </a-modal>
    <!-- / Modal -->
  </div>
</template>

<script>
import timelines from "../../../api/timelines";
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
import axios from "axios";
const _ = require("lodash");
export default {
  props: ["entry", "myPresenceId", "membership", "tenantId"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      isLoadingThumbnail: false,
      thumbnailUrl: null,
      thumbnailExpiresAt: null,
      thumbnailKey: 1,

      isLoading: false,
      mediaUrl: null,
      mediaExpiresAt: null,
      modalVisible: false,
      mediaKey: 1,

      thumbnailLoadAgainTryCount: 0,
      loadAgainTryCount: 0,
    };
  },
  created() {
    this.loadThumbnail();
    this.loadMedia();
  },
  computed: {
    sentByMe() {
      return this.entry["createdByPresenceId"] === this.myPresenceId;
    },

    thumbnailKeyUniq() {
      return "thumbnail-" + Math.random() + "-" + this.thumbnailKey;
    },

    mediaKeyUniq() {
      return "media-" + Math.random() + "-" + this.mediaKey;
    },
  },
  methods: {
    loadAgainIfThumbnailDoesNotExist() {
      let vm = this;
      if (this.thumbnailUrl) {
        axios.create()
          .get(this.thumbnailUrl)
          .then(() => {
            console.log("thumbnail exists");
          })
          .catch(() => {
            // console.log('an error occured');
            // if (e.response.status == 404) {
            if (vm.thumbnailLoadAgainTryCount > 2) {
              return false;
            }
            window.setTimeout(() => {
              vm.thumbnailLoadAgainTryCount += 1;
              vm.loadThumbnail();
            }, 500 * (this.thumbnailLoadAgainTryCount + 1));
            // }
          });
      }
    },

    loadAgainIfMediaDoesNotExist() {
      let vm = this;
      if (this.mediaUrl) {
        axios.create()
          .get(this.mediaUrl)
          .then(() => {
            console.log("media exists");
          })
          .catch(() => {
            // console.log('an error occured');
            // if (e.response.status == 404) {
            if (vm.loadAgainTryCount > 5) {
              return false;
            }
            window.setTimeout(() => {
              vm.loadAgainTryCount += 1;
              vm.loadMedia();
            }, 500 * (this.loadAgainTryCount + 1));
            // }
          });
      }
    },

    loadThumbnail() {
      let vm = this;
      vm.isLoadingThumbnail = true;
      timelines
        .getTimelineEntryThumbProtectedUrl(
          this.tenantId,
          this.entry["ownerId"],
          this.entry["id"]
        )
        .then((r) => {
          //   console.log(r);
          vm.isLoadingThumbnail = false;
          vm.thumbnailUrl = r.data.url;
          vm.thumbnailKey += 1;
          vm.loadAgainIfThumbnailDoesNotExist();
          vm.thumbnailExpiresAt = r.data.expiresAt;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading thumbnail");
        });
    },

    loadMedia() {
      let vm = this;
      vm.isLoading = true;
      timelines
        .getTimelineEntryMediaProtectedUrl(
          this.tenantId,
          this.entry["ownerId"],
          this.entry["id"]
        )
        .then((r) => {
          //   console.log(r);
          vm.isLoading = false;
          vm.mediaUrl = r.data.url;
          vm.mediaKey += 1;
          vm.loadAgainIfMediaDoesNotExist();
          vm.mediaExpiresAt = r.data.expiresAt;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading media");
        });
    },

    getMemberName(presenceId) {
      let member = _.find(this.membership, { id: presenceId });
      return member ? member.displayName : "Unknown";
    },

    handleMessageClicked() {
      this.modalVisible = true;
      if (!this.mediaUrl) {
        this.loadMedia();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;

  .entry-wrapper {
    flex-grow: 1;
    max-width: 45%;
    // background: #5155ea;
  }

  .entry {
    background: #e0e0e0;
    border-radius: 7px;
    padding: 13px 15px;
    border-bottom-left-radius: 0 !important;

    // To try and fix timeline scrolling
    min-height: 290px;
  }

  .sent-at {
    text-align: left;
    margin-top: 5px;
    font-size: 12px;
  }

  &.sent-by-me {
    justify-content: flex-end;

    .entry {
      background: #5155ea;
      color: #fff;
      border-bottom-left-radius: 7px !important;
      border-bottom-right-radius: 0 !important;
    }

    .sent-at {
      text-align: right;
    }

    .loader {
      text-align: right;
    }
  }

  .sent-by {
    color: #5155ea;
    // margin-bottom: 1px;
  }

  .location {
    color: #377e20;
    margin-top: 3px;
  }

  .loader {
    margin-top: 10px;
  }

  .image {
    margin-top: 10px;
    padding-bottom: 10px;
    height: 250px;
    width: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
}
.fullscreen-image {
  padding: 15px;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>