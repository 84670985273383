<template>
  <div>
    <div class="flex-wrapper video-message" :class="{ 'sent-by-me': sentByMe }">
      <div class="entry-wrapper">
        <div class="entry" @click.prevent="handleMessageClicked">
          <div class="sent-by" v-if="!sentByMe">
            {{ getMemberName(entry["createdByPresenceId"]) }}
          </div>

          <!-- Loading -->
          <div class="loader" v-if="isLoading">
            <a-spin></a-spin>
          </div>
          <!-- / Loading -->

          <!-- Video loaded -->
          <div v-if="!isLoading && video">
            <img
              class="thumbnail"
              :src="video.thumbnail.publicUrl"
              :alt="message"
            />
          </div>
          <!-- / Video loaded -->

          {{ message }}
        </div>
        <div class="sent-at" :key="timeAgoRefreshKey">
          {{ displayTimeAgo(entry["whenCreated"]) }}
        </div>
      </div>
    </div>

    <a-modal
      v-if="video"
      class="fullscreen-modal"
      v-model="modalVisible"
      centered
      :title="video.displayName"
      :footer="false"
    >
      <video
        controls
        width="100%"
        v-if="video && modalVisible"
        class="video-viewer"
        autoplay
        loop
      >
        <source :src="video.media.publicUrl" :type="video.media.contentType" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </a-modal>
  </div>
</template>

<script>
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
import howToVideos from "../../../api/how-to-videos";
import timelines from "../../../api/timelines";
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  props: ["entry", "myPresenceId", "membership", "ownerType", "tenantId"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      isLoading: false,
      video: null,
      modalVisible: false,
    };
  },
  created() {
    this.loadVideo();
  },
  computed: {
    ...mapGetters("incidentViewer", {
      incident: "incident",
    }),

    message() {
      return this.entry["content"]["$v"]["text"];
    },

    sentByMe() {
      // return this.entry.;
      return this.entry["createdByPresenceId"] === this.myPresenceId;
    },
  },
  methods: {
    getMemberName(presenceId) {
      let member = _.find(this.membership, { id: presenceId });
      return member ? member.displayName : "Unknown";
    },

    loadVideo() {
      let vm = this;
      vm.isLoading = true;
      howToVideos
        .getHowToVideos()
        .then((r) => {
          vm.isLoading = false;
          vm.video = _.find(r.data, {
            id: vm.entry["content"]["$v"]["helpVideoId"],
          });
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading help video");
        });
    },

    handleMessageClicked() {
      if (!this.video) {
        return false;
      }
      this.modalVisible = true;
      if (this.ownerType == 3 && this.incident) {
        // incident
        if (
          this.incident.createdBy == this.myPresenceId &&
          (!this.incident.metadataIds ||
            (this.incident.metadataIds &&
              !this.incident.metadataIds.includes(this.video.id)))
        ) {
          let params = {
            Behaviour: 0,
            CreatedByPresenceId: this.myPresenceId,
            OwnerId: this.incident.id,
            OwnerType: this.ownerType,
            MetadataId: this.video.id,
            Content: {
              $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
              $v: {
                Text: "WATCHING video: " + this.video.displayName,
              },
            },
          };
          timelines
            .postTimelineEntry(this.tenantId, params)
            .then(() => {})
            .catch((e) => {
              console.log(e);
              // vm.$message.error('Error posting video watched message to timeline');
            });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;

  .entry-wrapper {
    // flex-grow: 1;
    max-width: 45%;
    // background: #5155ea;
  }

  .entry {
    background: #e0e0e0;
    border-radius: 7px;
    padding: 13px 15px;
    cursor: pointer;
    border-bottom-left-radius: 0 !important;
  }

  .sent-at {
    text-align: left;
    margin-top: 5px;
    font-size: 12px;
  }

  &.sent-by-me {
    justify-content: flex-end;

    .entry {
      background: #5155ea;
      color: #fff;
      border-bottom-left-radius: 7px !important;
      border-bottom-right-radius: 0 !important;
    }

    .sent-at {
      text-align: right;
    }
  }

  .sent-by {
    color: #5155ea;
    // margin-bottom: 1px;
  }

  .loader {
    margin-bottom: 5px;
  }

  .thumbnail {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.video-viewer {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
</style>