var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"timeline",staticClass:"timeline-wrapper",class:{ jumping: _vm.jumping }},[(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c('a-spin')],1):_vm._e(),_c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({ active: true, callback: _vm.onWaypoint }),expression:"{ active: true, callback: onWaypoint }"}]}),_c('div',{staticClass:"timeline"},_vm._l((_vm.timeline),function(dateTimeline,date){return _c('div',{key:date,staticClass:"date-wrapper"},[_c('div',{staticClass:"date-display"},[_vm._v(_vm._s(_vm.getDateDisplay(date)))]),_vm._l((dateTimeline),function(entry,i){return _c('div',{key:i,staticClass:"timeline-entry"},[(
            _vm.determineEntryType(entry) == 'Soteria.Models.MarkdownChatEntry'
          )?_c('markdown-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry,"membership":_vm.membership,"my-presence-id":_vm.myPresenceId}}):_vm._e(),(_vm.determineEntryType(entry) == 'Soteria.Models.TasksChatEntry')?_c('tasks-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry,"membership":_vm.membership,"my-presence-id":_vm.myPresenceId},on:{"view-tasks":_vm.viewTasks}}):_vm._e(),(_vm.determineEntryType(entry) == 'Soteria.Models.MediaChatEntry')?_c('media-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry,"membership":_vm.membership,"my-presence-id":_vm.myPresenceId,"tenant-id":_vm.tenantId}}):_vm._e(),(_vm.determineEntryType(entry) == 'Soteria.Models.StatusChatEntry')?_c('status-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry}}):_vm._e(),(_vm.determineEntryType(entry) == 'Soteria.Models.GuidanceChatEntry')?_c('guidance-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"my-presence-id":_vm.myPresenceId,"membership":_vm.membership,"entry":entry}}):_vm._e(),(
            _vm.determineEntryType(entry) == 'Soteria.Models.AudioEntryDetails'
          )?_c('audio-entry-details',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry,"membership":_vm.membership,"my-presence-id":_vm.myPresenceId,"tenant-id":_vm.tenantId}}):_vm._e(),(
            _vm.determineEntryType(entry) == 'Soteria.Models.HelpVideoChatEntry'
          )?_c('help-video-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry,"membership":_vm.membership,"my-presence-id":_vm.myPresenceId,"tenant-id":_vm.tenantId,"owner-type":_vm.ownerType}}):_vm._e(),(
            _vm.determineEntryType(entry) == 'Soteria.Models.AnnouncementChatEntry'
          )?_c('announcement-chat-entry',{ref:_vm.getEntryId(entry),refInFor:true,attrs:{"id":_vm.getEntryId(entry),"entry":entry,"membership":_vm.membership,"my-presence-id":_vm.myPresenceId,"tenant-id":_vm.tenantId}}):_vm._e()],1)})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }