import axios from "axios"

export default {
    getChecklistsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Checklists');
    },

    getChecklistTasks(tenantId, checklistId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Checklists/' + checklistId + '/Tasks')
    },

    addChecklistTask(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Tasks', params);
    },

    updateChecklistTask(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Tasks', params);
    },

    deleteChecklistTask(tenantId, checklistId, id) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Checklists/' + checklistId + '/Tasks/' + id);
    }
}