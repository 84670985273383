<template>
  <a-modal centered v-model="showModal" title="Add Staff Members" :footer="null">
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>

    <!-- Loaded -->
    <div v-if="!isLoading">

      <!-- Topbar -->
      <div class="topbar" v-if="staffMembers.length">
        <div class="left">
          {{ selectedIds.length }}
          {{ selectedIds.length == 1 ? "staff member" : "staff members" }}
          selected
        </div>
        <div class="right">
          <a-button @click.prevent="addStaffMembers" :disabled="selectedIds.length === 0" type="primary">Add Staff
            Members</a-button>
        </div>
      </div>
      <!-- / Topbar -->

      <!-- Search Bar -->
      <div class="search-bar">
        <div class="search-bar-inner">
          <a-input-search placeholder="Search..." class="search-input" v-model="searchQuery" />
        </div>
      </div>
      <!-- / Search Bar -->

      <!-- No staff members -->
      <a-alert v-if="staffMembers.length === 0" type="error" message="There are no staff members that can be added" />
      <!-- / No staff members -->

      <!-- Staff members -->
      <div class="staff-members-wrapper" v-if="staffMembers.length">
        <a-row :gutter="10">
          <a-col class="staff-member-column" :span="12" v-for="member in staffMembers" :key="member.id">
            <div class="staff-member-list-item" @click.prevent="toggleMemberSelected(member)"
              :class="{ selected: isMemberSelected(member) }">
              <div class="left">
                <presence-thumbnail :owner-id="member.ownerId" :presence-id="member.id">
                </presence-thumbnail>
              </div>
              <div class="middle">
                {{ member.displayName }}
              </div>
              <div class="right">
                <a-icon theme="twoTone" two-tone-color="#52c41a" v-if="isMemberSelected(member)" type="check-circle" />
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- / Staff members -->
    </div>
    <!-- / Loaded -->
  </a-modal>
</template>

<script>
import PresenceThumbnail from "../../components/Presences/PresenceThumbnail.vue";
import organisations from "../../api/organisations";
const _ = require("lodash");
export default {
  props: ["visible", "tenantId", "orgId", "membership"],

  components: { PresenceThumbnail },

  data() {
    return {
      isLoading: false,
      allStaffMembers: [],
      selectedIds: [],

      searchQuery: ''
    };
  },

  watch: {
    showModal(isVisible) {
      if (isVisible) {
        this.selectedIds = [];
        this.fetch();
      }
    },
  },

  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      organisations
        .getMembershipForOrg(this.tenantId, this.orgId)
        .then((r) => {
          vm.isLoading = false;
          vm.allStaffMembers = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading staff members");
        });
    },

    addStaffMembers() {
      if (!this.selectedIds.length) {
        return false;
      }

      this.$emit("add-staff-members", this.selectedIds);
      this.$emit("close-modal");
    },

    isMemberSelected(member) {
      return this.selectedIds.includes(member.id);
    },

    toggleMemberSelected(member) {
      this.selectedIds = _.xor(this.selectedIds, [member.id]);
    },
  },

  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },

    staffMembers() {
      let filtered = _.filter(this.allStaffMembers, (member) => {
        return _.find(this.membership, { id: member.id }) == undefined;
      });

      if (this.searchQuery && this.searchQuery.trim().length) {
        filtered = _.filter(filtered, (option) => {
          return option.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return _.orderBy(filtered, i => i.displayName.toLowerCase()[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
}

.search-bar {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;

  .search-bar-inner {
    max-width: 50%;
    width: 100%;
  }
}

.topbar {
  margin-bottom: 15px;
  background: rgb(247, 248, 250);
  border-radius: 10px;
  padding: 10px;
  display: flex;

  .left {
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}

.staff-member-column {
  margin-bottom: 10px;
}

.staff-members-wrapper {
  max-height: 350px;
  padding-right: 10px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.staff-member-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 6px;
  // background: #eee;
  border: 1px solid #eee;
  padding: 8px 9px;

  &:last-child {
    margin-bottom: 0;
  }

  //  &:hover {
  //   color: #5155ea;
  //   border: 1px solid #5155ea;
  // }
  &:hover {
    background: #f9f9f9;
  }

  &.selected {
    background: #eee !important;
  }

  .left {
    flex-shrink: 1;
  }

  .middle {
    flex-grow: 1;
    padding-left: 15px;
  }

  .right {
    flex-shrink: 1;

    .anticon {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}
</style>