<template>
  <div class="typing-indicators" v-if="typingPresenceIdsWithoutMe.length">
    {{ getPresenceNames(typingPresenceIdsWithoutMe) }}
    {{ typingPresenceIdsWithoutMe.length == 1 ? "is" : "are" }} typing...
  </div>
</template>

<script>
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  props: ["myPresenceId"],

  computed: {
    ...mapGetters("timeline", {
      typingPresenceIds: "typingPresenceIds",
    }),

    ...mapGetters("incidentViewer", {
      incidentMembership: "membership",
    }),

    ...mapGetters("chatViewer", {
      chatMembership: "membership",
    }),

    typingPresenceIdsWithoutMe() {
      return _.without(_.uniq(this.typingPresenceIds), this.myPresenceId);
      // return _.uniq(this.typingPresenceIds);
    },
  },

  methods: {
    getPresenceNames(presenceIds) {
      let names = [];

      _.each(presenceIds, (presenceId) => {
        let member = null;
        if (this.chatMembership && this.chatMembership.length) {
          member = _.find(this.chatMembership, { id: presenceId });
        }
        if (
          !member &&
          this.incidentMembership &&
          this.incidentMembership.length
        ) {
          member = _.find(this.incidentMembership, { id: presenceId });
        }
        names.push(member ? member.displayName : "Unknown");
      });

      return names.join(" and ");
    },
  },
};
</script>

<style scoped lang="scss">
.typing-indicators {
  text-align: left;
  width: 100%;
  padding-bottom: 10px;
}
</style>