<template>
  <div>
    <view-guidance-message-modal
      :visible="showModal"
      @close-modal="closeModal"
      :my-presence-id="myPresenceId"
      :guidance-pack-id="entry['content']['$v']['guidancePackId']"
      :content-id="entry['content']['$v']['contentId']"
    ></view-guidance-message-modal>

    <div class="flex-wrapper" :class="{ 'sent-by-me': sentByMe }">
      <div class="entry-wrapper">
        <div class="entry" @click.prevent="handleMessageClicked">
          <div class="sent-by" v-if="!sentByMe">
            {{ getMemberName(entry["createdByPresenceId"]) }}
          </div>
          {{message}}
        </div>
        <div class="sent-at" :key="timeAgoRefreshKey">
          {{ displayTimeAgo(entry["whenCreated"]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
import ViewGuidanceMessageModal from "../../ViewGuidanceMessageModal.vue";
const _ = require("lodash");
export default {
  components: { ViewGuidanceMessageModal },
  props: ["entry", "myPresenceId", "membership"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    message() {
      return this.entry["content"]["$v"]["text"];
    },

    sentByMe() {
      // return this.entry.;
      return this.entry["createdByPresenceId"] === this.myPresenceId;
    },
  },
  methods: {
    getMemberName(presenceId) {
      let member = _.find(this.membership, { id: presenceId });
      return member ? member.displayName : "Unknown";
    },

    handleMessageClicked() {
      this.openModal();
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    }
  },
};
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;

  .entry-wrapper {
    flex-grow: 1;
    max-width: 45%;
    // background: #5155ea;
  }

  .entry {
    background: #e0e0e0;
    border-radius: 7px;
    padding: 13px 15px;
    border-bottom-left-radius: 0 !important;
    cursor: pointer;
    white-space: pre-line;
  }

  .sent-at {
    text-align: left;
    margin-top: 5px;
    font-size: 12px;
  }

  &.has-location {
    cursor: pointer;
  }

  &.sent-by-me {
    justify-content: flex-end;

    .entry {
      background: #5155ea;
      color: #fff;
      border-bottom-left-radius: 7px !important;
      border-bottom-right-radius: 0 !important;
    }

    .sent-at {
      text-align: right;
    }
  }

  .sent-by {
    color: #5155ea;
    // margin-bottom: 1px;
  }

  .location {
    color: #377e20;
    margin-top: 3px;
  }
  &.sent-by-me {
    .location {
      color: #b9eea8 !important;
    }
  }

  .show-more-less-wrapper {
    margin-top: 15px;
    .ant-btn-link {
      color: #fff;
    }
  }
}
</style>