<template>
  <a-modal centered v-model="showModal" title="Add Playbook" :footer="null">
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- No checklists -->
      <a-alert
        v-if="checklistsToShow.length === 0"
        type="error"
        message="There are no playbooks that can be added"
      />
      <!-- / No checklists -->

      <div class="checklist-list-wrapper">
        <a-row :gutter="10">
          <a-col
            v-for="checklist in checklistsToShow"
            :span="12"
            class="checklist-list-item-col"
            :key="checklist.id"
          >
            <div
              class="checklist-list-item"
              @click.prevent="() => addChecklist(checklist)"
            >
              <div class="left" v-if="!isChecklistOwnedByCurrentOrg(checklist)">
                <organisation-thumbnail
                  class="org-thumbnail"
                  :tenant-id="tenantId"
                  :org-id="checklist.ownerId"
                ></organisation-thumbnail>
              </div>
              <div class="right">
                <div>
                  <b>{{ checklist.displayName }}</b>
                  {{ checklist.description }}
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- / Loaded -->
  </a-modal>
</template>

<script>
import checklistTasks from "../../api/checklist-tasks";
import OrganisationThumbnail from "../Organisations/OrganisationThumbnail.vue";
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  props: ["visible", "tenantId", "orgId"],

  components: { OrganisationThumbnail },

  data() {
    return {
      isLoading: false,
      checklists: [],
    };
  },

  watch: {
    showModal(isVisible) {
      if (isVisible) {
        this.fetch();
      }
    },
  },

  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      checklistTasks
        .getChecklistsForOrg(this.tenantId, this.orgId)
        .then((r) => {
          vm.isLoading = false;
          vm.checklists = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading checklists");
        });
    },

    addChecklist(checklist) {
      this.$emit("add-checklist", checklist);
      this.$emit("close-modal");
    },

    isChecklistOwnedByCurrentOrg(checklist) {
      return checklist.ownerId == this.orgId;
    },
  },

  computed: {
    ...mapGetters("metadata", {
      metadataIds: "metadataIds",
    }),

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },

    checklistsToShow() {
      let vm = this;
      return _.filter(this.checklists, (checklist) => {
        return !vm.metadataIds.includes(checklist.id);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
}

.checklist-list-wrapper {
  .checklist-list-item-col {
   margin-bottom: 10px;
  }

  .checklist-list-item {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    // margin-bottom: 10px;
    border-radius: 6px;
    // background: #eee;
    border: 1px solid #eee;
    padding: 8px 9px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: #f9f9f9;
    }

    b {
      display: block;
      font-weight: 600;
      // color: #1890ff;
    }

    .left {
      flex-shrink: 1;
      padding-right: 15px;
    }

    .right {
      flex-grow: 1;
    }
  }
}

.org-thumbnail {
  display: inline;
  margin-right: 8px;
}
</style>