<template>
<div class="status-chat-entry">
    {{ message }}
</div>  
</template>

<script>
export default {
    props: ['entry'],
    computed: {
        message() {
            return this.entry['content']['$v']['message']
        }
    }
}
</script>

<style scoped>
.status-chat-entry {
    text-align: center;
}
</style>