<template>
  <div>
    <div class="flex-wrapper" :class="{ 'sent-by-me': sentByMe }">
      <div class="entry-wrapper">
        <div class="entry" @click.prevent="handleMessageClicked">
          <div class="sent-by" v-if="!sentByMe">
            {{ getMemberName(entry["createdByPresenceId"]) }}
          </div>

          <div class="loader" v-if="isLoading">
            <a-spin></a-spin>
          </div>

          <div class="playback-flex-wrapper">
            <div class="left">
              <div class="audio-wrapper" v-if="audioUrl && !isLoading">
                <audio :src="audioUrl" ref="player" :id="playerId">
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>

                <a-button
                  @click.prevent="toggleAudio"
                  shape="circle"
                  :style="buttonStyle"
                >
                  <img
                    v-if="isPlaying"
                    width="13"
                    src="@/assets/stop-icon.svg"
                    alt="Stop audio"
                  />
                  <img
                    v-if="!isPlaying"
                    width="13"
                    src="@/assets/play-icon.svg"
                    alt="Play audio"
                  />
                </a-button>
              </div>
            </div>
            <div class="right">
              <div class="transcription" v-if="!isLoading">
                {{ entry["content"]["$v"]["transcriptionDisplayText"] }}
              </div>
            </div>
          </div>

          <div v-if="lowConfidenceTranscription" class="low-confidence">
            Low confidence transcription. Listen to be certain.
          </div>
        </div>
        <div class="sent-at" :key="timeAgoRefreshKey">
          {{ displayTimeAgo(entry["whenCreated"]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
const _ = require("lodash");
import timelines from "../../../api/timelines";
export default {
  props: ["entry", "myPresenceId", "membership", "tenantId"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      audioUrl: null,
      audioExpiresAt: null,
      isLoading: false,

      listenerActive: false,
      isPlaying: false,
      audioLoaded: false,
    };
  },
  computed: {
    playerId() {
      return "audio-player-" + Math.random();
    },

    message() {
      return this.entry["content"]["$v"]["text"];
    },

    sentByMe() {
      // return this.entry.;
      return this.entry["createdByPresenceId"] === this.myPresenceId;
    },

    lowConfidenceTranscription() {
      return this.entry["content"]["$v"]["transcriptionConfidence"] < 60;
    },

    buttonStyle() {
      return {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "center",
      };
    },
  },
  created() {
    this.getAudio();
  },
  methods: {
    getMemberName(presenceId) {
      let member = _.find(this.membership, { id: presenceId });
      return member ? member.displayName : "Unknown";
    },

    handleMessageClicked() {
      if (this.hasLocation) {
        this.locationModalVisible = true;
      }
      return false;
    },

    getAudio() {
      let vm = this;
      vm.isLoading = true;
      timelines
        .getAudio(
          this.tenantId,
          this.entry["ownerId"],
          this.entry["content"]["$v"]["sessionId"]
        )
        .then((r) => {
          vm.isLoading = false;
          vm.audioUrl = r.data.url;
          vm.audioExpiresAt = r.data.expiresAt;
          vm.$nextTick(() => {
            vm.setupAudioPlayer();
          });
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading audio");
        });
    },

    playbackListener() {
      var audio = this.$refs.player;
      this.playbackTime = audio.currentTime;
      audio.addEventListener("ended", this.endListener);
      audio.addEventListener("pause", this.pauseListener);
    },

    //Function to run when audio is paused by user
    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },

    //Function to run when audio play reaches the end of file
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },

    //Remove listeners after audio play stops
    cleanupListeners() {
      var audio = this.$refs.player;
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.endListener);
      audio.removeEventListener("pause", this.pauseListener);
    },

    toggleAudio() {
      var audio = this.$refs.player;
      //var audio = document.getElementById("audio-player");
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        // audio.pause();
        audio.pause();
        audio.currentTime = 0;
        this.isPlaying = false;
      }
    },

    setupAudioPlayer() {
      this.$nextTick(function () {
        var audio = this.$refs.player;
        audio.addEventListener(
          "canplay",
          function () {
            this.audioLoaded = true;
          }.bind(this)
        );

        //Wait for audio to begin play, then start playback listener function
        this.$watch("isPlaying", function () {
          if (this.isPlaying) {
            var audio = this.$refs.player;
            //console.log("Audio playback started.");
            //prevent starting multiple listeners at the same time
            if (!this.listenerActive) {
              this.listenerActive = true;
              //for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
              audio.addEventListener("timeupdate", this.playbackListener);
            }
          }
        });

        //Update current audio position when user drags progress slider
        this.$watch("playbackTime", function () {
          var diff = Math.abs(
            this.playbackTime - this.$refs.player.currentTime
          );

          //Throttle synchronization to prevent infinite loop between playback listener and this watcher
          if (diff > 0.01) {
            this.$refs.player.currentTime = this.playbackTime;
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.playback-flex-wrapper {
  display: flex;

  .left {
    flex-shrink: 1;
    padding-right: 15px;
  }

  .right {
    flex-grow: 1;
  }
}

.flex-wrapper {
  display: flex;

  .entry-wrapper {
    // flex-grow: 1;
    max-width: 45%;
    // background: #5155ea;
  }

  .entry {
    background: #e0e0e0;
    border-radius: 7px;
    padding: 13px 15px;
    border-bottom-left-radius: 0 !important;
  }

  .sent-at {
    text-align: left;
    margin-top: 5px;
    font-size: 12px;
  }

  &.has-location {
    cursor: pointer;
  }

  &.sent-by-me {
    justify-content: flex-end;

    .entry {
      background: #5155ea;
      color: #fff;
      border-bottom-left-radius: 7px !important;
      border-bottom-right-radius: 0 !important;
    }

    .sent-at {
      text-align: right;
    }
  }

  .sent-by {
    color: #5155ea;
    // margin-bottom: 1px;
  }

  .location {
    color: #377e20;
    margin-top: 3px;
  }

  .loader,
  .audio-wrapper {
    margin-top: 1px;
  }

  .transcription {
    margin-top: 5px;
  }

  .low-confidence {
    margin-top: 10px;
    color: #ff0000;
  }
}
</style>