<template>
  <div>
    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Grid -->
    <div v-if="!isLoading">
      <!-- {{ videos }} -->

      <a-input-search
        placeholder="Search videos..."
        size="large"
        class="search-input"
        v-model="searchQuery"
      />

      <a-alert v-if="videosSorted.length === 0 && !isLoading"
        type="error"
        banner
        message="No results to show"
      />

      <a-row :gutter="30">
        <a-col
          :span="colSpan"
          v-for="(video, i) in videosSorted"
          :key="getVideoKey(i)"
        >
          <a-card
            hoverable
            class="video-card"
            @click.prevent="() => $emit('video-selected', video)"
          >
            <img
              slot="cover"
              :alt="video.displayName"
              :src="video.thumbnail.publicUrl"
            />
            <a-card-meta :title="video.displayName"> </a-card-meta>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <!-- / Grid -->
  </div>
</template>

<script>
const _ = require("lodash");
import howToVideos from "../../api/how-to-videos";
export default {
  props: ["colSpan"],
  data() {
    return {
      isLoading: false,
      videos: [],
      searchQuery: "",
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    videosSorted() {
      let filtered = this.videos;

      if (this.searchQuery.trim().length) {
        filtered = _.filter(this.videos, (video) => {
          return video.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return _.orderBy(filtered, "displayName");
    },
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      vm.videos = [];
      howToVideos
        .getHowToVideos()
        .then((r) => {
          vm.isLoading = false;
          vm.videos = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading help videos");
        });
    },

    getVideoKey(i) {
      return "video-" + i;
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
  margin-top: 20px;
}

.video-card {
  margin-bottom: 15px;
}

.search-input {
  margin-bottom: 20px;
  width: 100%;
}

.ant-card {
  border: 0;
  background: transparent;
  text-align: center;
}
</style>