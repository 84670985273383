<template>
  <div class="announcement-chat-entry">
    <div class="flex-wrapper" :class="{ 'sent-by-me': sentByMe }">
      <div class="entry-wrapper">
        <div class="entry" @click.prevent="handleMessageClicked">
          <div class="sent-by" v-if="!sentByMe && false">
            {{ getMemberName(entry["createdByPresenceId"]) }}
          </div>

          <div class="title">
            <a-icon type="notification" /> Announcement
          </div>

          <div class="subtitle">
            {{ entry['content']['$v']['displayName'] }}
          </div>

          <!-- Loading -->
          <div v-if="!announcement || isLoading" class="loading">
            <a-spin />
          </div>
          <!-- / Loading -->

          <!-- Announcement -->
          <div v-if="announcement && !isLoading">
            <announcement-chat-entry-inner :tenant-id="tenantId"
            :sent-by-me="sentByMe"
            :timeline="timeline"
              :announcement="announcement"></announcement-chat-entry-inner>
          </div>
          <!-- / Announcement -->

        </div>
        <div class="sent-at" :key="timeAgoRefreshKey">
          {{ displayTimeAgo(entry["whenCreated"]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import announcements from '../../../api/announcements';
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
const _ = require("lodash");
import { mapGetters } from 'vuex';
import AnnouncementChatEntryInner from './AnnouncementChatEntry/AnnouncementChatEntryInner.vue';
import timelines from "../../../api/timelines";
import eventBus from "../../../event-bus";

export default {
  components: { AnnouncementChatEntryInner },

  props: ["entry", "myPresenceId", "membership", "tenantId"],

  mixins: [Dates, TimeAgoRefresher],

  data() {
    return {
      isLoadingTimeline: false,
      timeline: []
    };
  },

  created() {
    if (this.announcement) {
      this.loadTimeline();
      this.listenForNewMessages();
    }
  },

  beforeDestroy() {
    eventBus.$off('new-chat-entry-for-timeline');
  },

  watch: {
    announcement(newVal, oldVal) {
      if (!oldVal) {
        this.loadTimeline();
        this.listenForNewMessages();
      }
    }
  },

  computed: {
    ...mapGetters('announcements', {
      announcements: 'announcements',
      isLoadingAnnouncements: 'isLoading'
    }),

    announcement() {
      return this.announcements.find(
        (announcement) => announcement.id === this.entry["content"]["$v"]["announcementId"]
      );
    },

    sentByMe() {
      return this.entry["createdByPresenceId"] === this.myPresenceId;
      // return false;
    },

    isLoading() {
      return (
        !this.announcement
        || this.isLoadingAnnouncements
        || (this.announcement && this.announcement.isLoadingGroupMembers)
        || this.isLoadingTimeline
        || this.timeline.length === 0
      );
    }
  },

  methods: {
    getMemberName(presenceId) {
      let member = _.find(this.membership, { id: presenceId });
      return member ? member.displayName : "Unknown";
    },

    addMessagesToTimeline(messages) {
      this.timeline = _.unionBy(this.timeline, messages, "id");
    },

    loadTimeline() {
      let vm = this;
      vm.isLoadingTimeline = true;
      vm.timeline = [];
      let beforeTicks = window.moment().format("x") * 10000;

      timelines.getPreviousNTimelineEntries(this.tenantId, this.announcement.id, beforeTicks, 30).then(r => {
        vm.addMessagesToTimeline(r.data);
        vm.isLoadingTimeline = false;
      }).catch(e => {
        console.error(e);
        vm.isLoadingTimeline = false;
      });
    },

    listenForNewMessages() {
      eventBus.$on('new-chat-entry-for-timeline', (newChatEntry) => {
        if (newChatEntry.ownerId === this.announcement.id) {
          this.addMessagesToTimeline([newChatEntry]);
        }
      })
    }
  },
};
</script>

<style lang="scss">
.announcement-chat-entry {
  .flex-wrapper {
    display: flex;

    .entry-wrapper {
      flex-grow: 1;
      max-width: 45%;
      // background: #5155ea;
    }

    .entry {
      background: #e0e0e0;
      border-radius: 7px;
      padding: 13px 15px;
      border-bottom-left-radius: 0 !important;
      white-space: pre-line;
    }

    .sent-at {
      text-align: left;
      margin-top: 5px;
      font-size: 12px;
    }

    &.sent-by-me {
      justify-content: flex-end;

      .entry {
        background: #5155ea;
        color: #fff;
        border-bottom-left-radius: 7px !important;
        border-bottom-right-radius: 0 !important;
      }

      .sent-at {
        text-align: right;
      }
    }

    .sent-by {
      color: #5155ea;
      // margin-bottom: 1px;
    }

    &.sent-by-me {
      .location {
        color: #b9eea8 !important;
      }
    }

    .title {
      font-weight: bold;
      font-size: 16px;

      .anticon {
        margin-right: 5px;
      }
    }

    .subtitle {
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: 500;
    }

    .loading {
      margin-top: 15px;
    }

    .show-more-less-wrapper {
      margin-top: 15px;

      .ant-btn-link {
        color: #fff;
      }
    }
  }
}
</style>