<template>
  <div>
    <input @change="uploadFile" type="file" ref="file" 
    accept="image/png, image/gif, image/jpeg"
    class="file-input" />
  </div>
</template>

<script>
import timelines from "../../api/timelines";
import {mapGetters} from "vuex";
export default {
  props: ["myPresenceId"],

  data() {
    return {
      isUploading: false,
    };
  },

  mounted() {
    this.promptForFile();
  },

  computed: {
    ...mapGetters("timeline", {
      tenantId: "tenantId",
      quickMessages: "quickMessages",
      ownerType: "ownerType",
      ownerId: "ownerId",
    }),
  },

  methods: {
    promptForFile() {
      this.$refs.file.click();
    },

    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    async uploadFile(e) {
      let vm = this;
      let file = e.target.files[0];

      let base64 = await this.getBase64(file);

      let content = {
        $t: "Soteria.Models.MediaChatEntry, Soteria.Models",
        $v: {
          MediaContentType: file.type,
          MediaType: 1,
          ThumbnailBase64Url: base64
        },
      };

      let params = {
        OwnerType: vm.ownerType,
        OwnerId: vm.ownerId,
        Behaviour: 0,
        CreatedByPresenceId: vm.myPresenceId,
        Content: content,
      };

      vm.isUploading = true;
      timelines
      .postTimelineEntry(this.tenantId, params)
      .then(r => {
        let timelineEntryId = r.data.id;
        timelines.addTimelineEntryMedia(
          timelineEntryId,
          file
        ).then(() => {
          vm.isUploading = false;
          vm.$emit('file-uploaded');
        }).catch(e => {
          console.log(e);
          vm.isUploading = false;
          vm.$message.error("Error uploading media");
        });
      }).catch(e => {
        vm.isUploading = false;
        console.log(e);
        vm.$message.error("Error uploading media");
      });
    },
  },
};
</script>

<style scoped>
.file-input {
  display: none;
}
</style>