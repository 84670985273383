<template>
  <a-modal
    class="fullscreen-modal"
    centered
    v-model="showModal"
    title="Send Help Video"
    :footer="null"
  >
    <div v-if="visible" class="inner-wrapper">
        <loading-screen :is-loading="isSending"></loading-screen>
        <help-video-grid
        @video-selected="sendVideo"
        :col-span="4"
      ></help-video-grid>
    </div>
  </a-modal>
</template>

<script>
import HelpVideoGrid from "../HelpVideos/HelpVideoGrid.vue";
import timelines from "../../api/timelines";
import { mapGetters } from "vuex";
import LoadingScreen from "../LoadingScreen.vue";

export default {
  components: { HelpVideoGrid, LoadingScreen },
  props: ["visible", "myPresenceId"],

  data() {
    return {
      isSending: false,
    };
  },

  computed: {
    ...mapGetters("timeline", {
      tenantId: "tenantId",
      ownerType: "ownerType",
      ownerId: "ownerId",
    }),

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },
  },

  methods: {
    sendVideo(video) {
      let vm = this;

      let content = {
        $t: "Soteria.Models.HelpVideoChatEntry, Soteria.Models",
        $v: {
          helpVideoId: video.id,
          text: video.displayName,
        },
      };

      let params = {
        OwnerType: vm.ownerType,
        OwnerId: vm.ownerId,
        Behaviour: 0,
        CreatedByPresenceId: vm.myPresenceId,
        Content: content,
      };

      vm.isSending = true;
      timelines
        .postTimelineEntry(this.tenantId, params)
        .then(() => {
          vm.isSending = false;
          vm.$emit("close-modal");
        })
        .catch((e) => {
          vm.isSending = false;
          console.log(e);
          vm.$message.error("Error sending message");
        });
    },
  },
};
</script>

<style scoped>
.inner-wrapper {
  padding: 24px;
  height: 100%;
  overflow-y: scroll;
}
</style>